import * as React from 'react'
import { Provider, connect } from 'react-redux'
import ReactSignupLoginComponent from 'react-signup-login-component';
import { helyosService, helyosLogin } from '../../../services/app-service';
import { withRouter } from 'react-router-dom'
import { Store } from '..';
import { setAuthenticationStatus } from '../../../state/actions';
import { ApplicationState, LoginStatusType } from '../../../state/models';
import { createSelector } from 'reselect';
import * as Swal from 'sweetalert2';
import { Button } from '../components/Button';

 class LoginPage extends React.Component<AuthDialogProps>  {
  public serversUrls;
  public selectedServer;

      constructor (props: AuthDialogProps) {
        super(props);
        this.loginWasClickedCallback = this.loginWasClickedCallback.bind(this);;
        this.signupWasClickedCallback = this.signupWasClickedCallback.bind(this);
        this.adminLogin = this.adminLogin.bind(this);
        this.changeServer = this.changeServer.bind(this);
        this.closeWindow = this.closeWindow.bind(this);
        this.escape = this.escape.bind(this);
        let defaultServer;

        if (!location.hostname.includes('.ivi.fraunhofer.de')) {
          this.serversUrls = [`http://${location.hostname}`]
          defaultServer = `http://${location.hostname}`
        } else {

          this.serversUrls = [ `http://${location.hostname}`, 'https://helyos-server.ivi.fraunhofer.de',
                               'https://helyos.safe20.ivi.fraunhofer.de', 'https://helyos.magpie.ivi.fraunhofer.de',
                               'https://helyos.elwbot.ivi.fraunhfoer.de', 'https://helyos.fsr.ivi.fraunhofer.de',
                               'https://helyos.idea.ivi.fraunhofer.de']

          defaultServer = 'https://helyos.idea.ivi.fraunhofer.de';
        }

       this.selectedServer = window.sessionStorage.getItem('backendUrl');
       if (!this.selectedServer) {
        this.handleSelection(defaultServer);
       }
      }


     signupWasClickedCallback (data)  {
      console.log(data);
      Swal.fire({
        title: 'Administrator password',
        input: 'password',
        inputPlaceholder: 'Enter your password',
        inputAttributes: {
          maxlength: 10,
          autocapitalize: 'off',
          autocorrect: 'off'
        }
      }).then(adminPassword => {
        if(!adminPassword.value) { return };
        helyosService.register(data.username, data.username, data.password, adminPassword.value)
        .then( response => {
          console.log(response);
          if(response.id){
            //  Force reload();
            this.props.history.push('/p');
            this.props.history.push('/');
            Store.dispatch(setAuthenticationStatus('Please log in'));
          }
        })
        .catch(error => console.log(error));
        ;
       });

    };

  loginWasClickedCallback(data)  {
        console.log(this)
        helyosLogin(data.username, data.password)
        .then(logged => {
          if(logged){
                this.props.history.push('/p');
                const isAdminLogin = data.username === 'admin'; 
                if (isAdminLogin) {
                    Store.dispatch(setAuthenticationStatus(LoginStatusType.admin_logged));            
                } else {
                    Store.dispatch(setAuthenticationStatus(LoginStatusType.user_logged));            
                } 
          } else {
            alert('username or password incorrect');
          }
        });
  }

  closeWindow() {
    this.props.history.push('/p');
  }

  escape(event){
    if (event.key === 'Escape') {
      this.closeWindow();
    }
    
  }

    adminLogin() {
      Swal.fire({
        title: 'Administrator password',
        input: 'password',
        inputPlaceholder: 'Enter your password',
        inputAttributes: {
          maxlength: 10,
          autocapitalize: 'off',
          autocorrect: 'off'
        }
      }).then(adminPassword => {
        if(!adminPassword.value) { return };
        const data = {username: "admin", password: adminPassword.value };
        this.loginWasClickedCallback(data);
      });
    }

    handleSelection (value)  {
      if(value) {
        window.sessionStorage.setItem('backendUrl', value);
        location.reload();
      } else {
        alert('using ports 5000 and 5002');
        window.sessionStorage.setItem('backendUrl',  `http://${location.hostname}`);
        location.reload();
      };
    }

    changeServer() {
      Swal.fire({
        title: 'helyOS server',
        text: 'Let empty for localhost',
        input: 'text',
        inputPlaceholder: 'http://truckserver.com',
        inputAttributes: {
          maxlength: 100,
          autocapitalize: 'off',
          autocorrect: 'on'
        }
      }).then(sererURL => {
        if(sererURL.value) {
            window.sessionStorage.setItem('backendUrl', sererURL.value);
            location.reload();
        } else {
            alert('using ports 5000 and 5002');
            window.sessionStorage.setItem('backendUrl',  `http://${location.hostname}`);
            location.reload();
        };
      });
    }


     recoverPasswordWasClickedCallback (data)  {
      console.log(data);
      alert('Recover password callback, not implemented.');
    };
    render(){return (
        <div style={{height:"100%"}}>
            <div className="ModalDialog centred" onClick={this.props.cancelAction}>
            <div className="Dialog" onClick={event => {event.stopPropagation()}}>
            <div className="content">
            <button onClick={this.closeWindow} > <span style={{fontSize:26}}>X</span> </button>
            <div style={{display:'flex'}}>

                  <ReactSignupLoginComponent
                      title="Automation-App"
                      handleSignup={this.signupWasClickedCallback}
                      handleLogin={this.loginWasClickedCallback}
                      handleRecoverPassword={this.recoverPasswordWasClickedCallback}
                      usernameCustomLabel="Username"
                      submitLoginCustomLabel="Log in"
                      recoverPasswordCustomLabel="Forgot password?"
                  />
                  <div>
                      <div>
                            <h4>Select server</h4>
                            <Dropbox options={this.serversUrls} onSelect={this.handleSelection.bind(this)} selectedItem={this.selectedServer} />
                      </div>
                      <Button onClick={this.changeServer}> Other server... </Button>
                      <Button onClick={this.adminLogin}> Login as Admin </Button>
                      </div>
                  </div>
            </div>
            </div> 
            </div>
      </div>
    )};
};


interface AuthDialogProps {
  loginStatus: string;
  history?:any;
}


const AuthDialogSelector : (state: ApplicationState, historyObj: any) => (AuthDialogProps) = createSelector(
  (state: ApplicationState) => state.authentication,
  (state: ApplicationState, historyObj: any) => historyObj.history,
  (authentication, history) => {
      return {
        loginStatus: authentication,
        history
      }
  }
)

export const AuthenticationWindow_ = connect<AuthDialogProps>(AuthDialogSelector)(LoginPage)
export const AuthenticationWindow = withRouter(historyObj => <AuthenticationWindow_ { ...historyObj } />)

// Dropbox component
const Dropbox = ({ options, onSelect, selectedItem }) => {
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    onSelect(selectedValue);
  };

  return (
    <select onChange={handleSelectChange} value={selectedItem}>
      <option value="">Select an option</option>
      {options.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};