import * as React from 'react'
import { Provider, connect } from 'react-redux'
import {
    BrowserRouter as Router,
    Route, Switch, Link, HashRouter
} from 'react-router-dom'
import { Store } from '../../../state/store'


import { VehicleMap } from '../../command/container/CommandView'
import { ToolDetailsDialogConnected } from '../../command/container/MainDialogBox'
import { ObsViewConnected } from '../../obstacles/container/ObstacleView'
import { Header } from '../components/Header'
import { withSelectedPresence } from '../modules/with-presence'
import {AuthenticationWindow} from './Login'
import { AllPresencesBar, AllPresencesTimeTable } from '../../command/components/AllPresences'
import { PresenceBar, NullPresenceBar } from '../../command/components/PresenceBar'
import { Mission, Yard } from '../../../models/app.models'
import { cancelMission } from '../../../services/app-service'
import { EnrichedPresence } from '../../../state/enriched'
import { collapseSchedule } from '../../../state/actions'
import { Command } from '../../../state/models'
import { Button } from '../components/Button'



const ControlLateralBars: React.SFC<{  activeYard: Yard, presence: EnrichedPresence | undefined, missionsOutdated: boolean, commandViewControl:any }> = ({ activeYard, presence, missionsOutdated, commandViewControl }) => {
    if (!activeYard){ return (<div></div>)}
    const detailbar = presence ? <PresenceBar presence={ presence } cancelMission={ (m: Mission) => cancelMission(m) } viewOnly={ missionsOutdated } commandView={commandViewControl} /> : <NullPresenceBar commandView={commandViewControl}/>
    
    return (
        <div>
             <div>
                 <AllPresencesBar/>
                { detailbar }
            </div>
        </div>
    )
}



const MapViewer: React.SFC<{ commandViewControl: Command, activeYard: Yard }> =
                             ({ commandViewControl, activeYard }) => {
    if (!activeYard){ return (<div></div>)}
    const mapStyle ={};
       
    if (commandViewControl.collapseSchedule) {
        mapStyle['bottom'] = 20;
    } else {
        mapStyle['bottom'] = 320;
    }

    return (
            <div style={mapStyle} className="Content-Command">
                <VehicleMap />
            </div>
    )

}


const CBottomBar: React.SFC<{ commandViewControl: Command, activeYard: Yard }> = ({ commandViewControl, activeYard }) => {
       if (!activeYard){ return (<div></div>)}
    const schedStyle = {};
    const collapse = () => Store.dispatch(collapseSchedule(true));
    const extend = () => Store.dispatch(collapseSchedule(false));
    
    if (commandViewControl.collapseSchedule) {
        schedStyle['height'] = 20;
    } else {
        schedStyle['height'] = 320;
    }

    return (
                <div style={schedStyle} className="bottomBar">
                        <div style={{paddingBottom:'10px'}}> 
                            <Button style={{height:20, margin:0, paddingTop:1}} onClick={collapse}> &#x25BC;  </Button>
                            <Button style={{height:20, margin:0, paddingTop:1}} onClick={extend}> &#x25B2;  </Button>
                        </div>
                        <AllPresencesTimeTable/>;
                </div>
    )
}


const CLatBars = withSelectedPresence(({ activeYard,  missionsOutdated, presence, commandViewControl }) => <ControlLateralBars  activeYard={activeYard} presence={presence} missionsOutdated={missionsOutdated} commandViewControl={commandViewControl} />)
const MapViewerContainer = withSelectedPresence(({ commandViewControl, activeYard }) => <MapViewer commandViewControl={commandViewControl} activeYard={activeYard} />)
const CBotBars = withSelectedPresence(({ commandViewControl, activeYard }) => <CBottomBar commandViewControl={commandViewControl} activeYard={activeYard} />)
const ToolDetailsDialogConnectedwithPresence = withSelectedPresence(p => <ToolDetailsDialogConnected { ...p } />)


export class MainContainer extends React.Component<{}> {
    render () {
        let basename = '';
        if (location.pathname.includes('v2')) {
            basename = '/v2';
        }
        return (
            <Provider store={Store}>
                <Router basename={basename}>
                    <div>
                        <Header />

                        <Switch>


                            <Route exact path="/">
                       
                                <AuthenticationWindow/>
                      
                            </Route>



                             <Route exact path="/p:presenceId?" >
                                <div>
                                    <CLatBars/> 
                                    <MapViewerContainer />
                                    <CBotBars/> 

                                    <div>
                                        <ToolDetailsDialogConnectedwithPresence/>
                                    </div>

                                </div>
                            </Route>



                            <Route exact path="/layout/">

                                <ObsViewConnected />

                            </Route>
                        </Switch>
                    </div>
                </Router>
            </Provider>
        )
    }
}






