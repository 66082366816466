import * as React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { Mission, Specification, Yard, Presence } from '../../../models/app.models'
import { EnrichedPresence } from '../../../state/enriched'
import { ApplicationState, Command } from '../../../state/models'
import { allEntites, entityOfYard } from '../../../state/updatetableCollection'
import { InjectedPresence, withSelectedPresence } from '../../base/modules/with-presence'
import { LayeredMapComponent, MissionLayerProps, ObstacleLayerProps, Props as MapProps, TargetLayerProps, VehicleLayerProps, PathLayerProps } from '../../maps/components/LayeredMapComponent'
// import { Events as MapEvents, Props as VehicleMapProps, VehicleMapImpl } from '../../maps/components/VehicleMap'
import { Vehicle, VehiclePart } from '../../maps/renderables/vehicle'
import './CommandView.scss'



function makePolygons(pathPoints: any[], connectPoints,  size=100) {

let pathPolygons: any[] = [];

    if (connectPoints){
        let linePoints =   pathPoints.map(p =>{ 
                                            if (p.x || p.y) {
                                                return [p.x, p.y];
                                            } else {
                                                return [p[0], p[1]]
                                            }
        } );

        const endPoint = linePoints[linePoints.length - 1]
        const reversed = linePoints.slice();
        linePoints = linePoints.concat(reversed.reverse());

            
        pathPolygons.push(
            {
                id: 0, 
                layer: "temporary",
                points: [
                    [endPoint[0] - size ,  endPoint[1] - size],
                    [endPoint[0] + size ,  endPoint[1] - size],
                    [endPoint[0] + size ,  endPoint[1] + size],
                    [endPoint[0] - size ,  endPoint[1] + size],
                ],
            
            }
        )

        pathPolygons.push(
            {
                id: 1, 
                layer: "temporary",
                points: linePoints,
            
            }
        )

        return pathPolygons
}


 pathPolygons = pathPoints.map(p => {
        if (p.x || p.y) {
            return {
                id: p.id, 
                layer: "temporary",
                points: [
                [p.x - size ,  p.y - size],
                [p.x + size ,  p.y - size],
                [p.x + size ,  p.y + size],
                [p.x - size ,  p.y + size],
            ]}

        } 

        return {
            id: p.id, 
            layer: "temporary",
            points: [
            [p[0] - size ,  p[1] - size],
            [p[0] + size ,  p[1] - size],
            [p[0] + size ,  p[1] + size],
            [p[0] - size ,  p[1] + size],
        ]}
    })


    return pathPolygons;

}

const VehicleMapSelector: (state: ApplicationState, selected: InjectedPresence) => (MapProps) = createSelector(
    (state: ApplicationState) => state.activeYard,
    (state: ApplicationState) => state.entities.presences.items,
    (state: ApplicationState, selected: InjectedPresence) => selected.presence,
    (state: ApplicationState, selected: InjectedPresence) => selected.history,
    (state: ApplicationState) => state.entities.targets.items,
    (state: ApplicationState) => state.entities.targetAreas.items,
    (state: ApplicationState) => state.entities.obstacles.items,
    (state: ApplicationState) => state.entities.nobstacles.items,
    (state: ApplicationState) => state.entities.missions.items,
    (state: ApplicationState) => state.uiControl.playback.history,
    (state: ApplicationState) => {if(state.entities.guidelines) return state.entities.guidelines.items},
    (state: ApplicationState) => state.uiControl,
    (activeYard, presences, selectedPresence, history, targets, targetAreas, obstacles, nobstacles, missions, pathHistory, guidelines, uiControl) => {
        if(!activeYard) { return {}}
        const presenceArray = entityOfYard(activeYard, presences)
        const vehiclePositions = presenceArray.map((presence: Partial<Presence>) => (
            { x: presence.pose.x, 
              y: presence.pose.y, 
              id: presence.id, 
              picture: presence.picture,
              status: presence.status,
              transitionStartTime: presence.modified,
              deltaAnimation: presence.deltaAnimation,
             }));
        // const vehicleParts: VehiclePart[][] = presenceArray.map(p=>p.parts);

        const vehicleParts: VehiclePart[][] = presenceArray.map((presence: Partial<Presence>) => {
            const presenceSpec = presence.specification as any
            if (!presenceSpec) {return null}
            const parts: Specification[] = presenceSpec.vehicles ? presenceSpec.vehicles : presenceSpec
            return parts.map((spec, index) => {
                let angle = 0;
                if (presence.pose.orientations &&  presence.pose.orientations[ index ] !== null) {
                    angle = presence.pose.orientations[ index ] / 1000;
                } else {
                    angle = presence.pose.orientation? presence.pose.orientation : 0;
                }
                return {
                        angle: angle,
                        ...spec}
            })
        })


        let pathPolygons = [];
        let filterdPaths = [];
        if (pathHistory) {
            if (selectedPresence && selectedPresence.id) {
                filterdPaths =  pathHistory.filter(p=> p && (p.agentId || p.toolId) == selectedPresence.id);

                if (filterdPaths.length === 0) {
                    filterdPaths = pathHistory;
                } 

            } else {
                filterdPaths = pathHistory;
            }


            while (filterdPaths.length>1) {
                const p0 = filterdPaths[0];
                const paths = filterdPaths.filter(p=> p && p0 && p.toolId == p0.toolId);
                filterdPaths = filterdPaths.filter(p=> p && p0 && p.toolId !== p0.toolId);
                if(p0 && paths.length && paths[0]) {
                    if(p0.toolId) {
                        pathPolygons = pathPolygons.concat(makePolygons(paths, false))
                    } else {
                        pathPolygons = pathPolygons.concat(makePolygons(paths, true))
                    }
                }
            }

        }

        const guidelinesArray = entityOfYard(activeYard,guidelines);
        let guidelineAllPolygons = [];
        guidelinesArray.forEach( g => {
            guidelineAllPolygons = guidelineAllPolygons.concat(pathPolygons.concat(makePolygons(g.points, true)));
        });

        const vehicles = vehiclePositions.map((position, index) =>
            ({
                id: position.id,
                parts: vehicleParts[ index ],
                position: { x: position.x, y: position.y },
                transitionStartTime: position.transitionStartTime,
                deltaAnimation: position.deltaAnimation,
                status: position.status,
                picture: position.picture,
            })
        )

        const obstaclesArray = entityOfYard(activeYard, obstacles);
        const nobstaclesArray = entityOfYard(activeYard, nobstacles);
        const targetAreasArray = entityOfYard(activeYard, targetAreas);
        const missionsArray = allEntites(missions);
        return {
            missionLayer: {
                missions: missionsArray.filter(mission => mission.trajectory !== undefined && mission.trajectory.path !== undefined && selectedPresence && mission.presence_id === selectedPresence.id),
            } as MissionLayerProps,
            obstacleLayer: {
                nobstacles: [],
                obstacles: obstaclesArray,
            } as ObstacleLayerProps,
            newTargetLayer: {
                nobstacles: [],
                obstacles: targetAreasArray,
            } as ObstacleLayerProps,
            pathLayer: {
                path: pathPolygons
            } as PathLayerProps,
            guidelineLayer: {
                path: guidelineAllPolygons
            } as PathLayerProps,
            drivableLayer: {
                nobstacles: nobstaclesArray,
                obstacles:[],
            } as ObstacleLayerProps,
            targetLayer: {
                targets: entityOfYard(activeYard,targets),
            } as TargetLayerProps,
            vehicleLayer: {
                selectedPresence,
                vehicleClicked: (presenceId: string) => { history.push('/p' + presenceId) },
                vehicles: (vehicles as Vehicle[]),
            } as VehicleLayerProps,
            yard: activeYard,
            uiControl, 
        }
    }
)



export const VehicleMapConnected = connect<MapProps>(VehicleMapSelector)(LayeredMapComponent)
export const VehicleMap = withSelectedPresence(p => <VehicleMapConnected { ...p } />)