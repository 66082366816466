import * as React from 'react'
import { NavLink } from 'react-router-dom'

import './Header.scss'
import { PresenceDropdown } from './PresenceDropdown'
import { YardDropdown } from './YardDropdown'
import { Store } from '../../../state/store';
import { canMissionBeCancelled } from '../../../models/app.models';
import { allEntites } from '../../../state/updatetableCollection';
import { cancelMission, helyosService, scheduleMissionUpdates } from '../../../services/app-service';

let backendUrl =  window.sessionStorage.getItem('backendUrl');
export class Header extends React.Component<{}> {

    stopTheRock = ( ) =>  {
        const missions = allEntites(Store.getState().entities.missions.items);
        if (missions) {
            scheduleMissionUpdates(false)
            .then(()=> {
                const canStopTheRock = missions.filter(canMissionBeCancelled);
                canStopTheRock.forEach(m => cancelMission(m));
            })
        }
    }

    handleSignOut = () => {
        window.sessionStorage.removeItem('token');
        helyosService.logout();
        // go to login page in a consistent way
        if (window.location.pathname.includes('/v2')) {
            window.location.href = '/v2'
        }

    }

    renderLink (label: string, to: string, exact = false) {
        // quick-n-dirty inline styles, actually need to customise foundation styles and
        // create an is-active that works on anchors
        return <NavLink to={to} className="navLink" activeClassName="navActive" exact={exact}>{label}</NavLink>
    }

    renderLogo (to: string) {
        return <NavLink to={to} className="logo"> </NavLink>
        /*return <div src={logo}/>*/
    }

    renderStopAll () {
        return <button type="button" onClick={this.stopTheRock} className="alert button">Stop all vehicles</button> // German: Fahrzeuge stoppen
    }

    render () {
        return (
            <header className="Header">
                <div className="top-bar">
                    <div className="top-bar-left">
                        <ul className="menu">
                            <li>{this.renderLogo('/p')}</li>
                            <li>{this.renderLink('Layout', '/layout')}</li>
                            <YardDropdown />
                            <PresenceDropdown />
                        </ul>
                    </div>
                    <div className="top-bar-left" style={{color:'white'}}> AUTOMATION APP VERSION 2.2.1</div>
                    
                    <div className="top-bar-right">                                       
                        <ul className="menu">                            
                            <li style={{marginRight:40}}> <a style={{color:'gray'}} target='_blank' href={backendUrl}> helyOS Server: {backendUrl}</a></li>
                            <li>{this.renderStopAll()}</li>
                            <li><a href="#" onClick={this.handleSignOut}>Sign Out</a></li>
                        </ul>
                    </div>
                </div>
            </header >
        )
    }
}
