import { Axle, Position, Presence, Specification } from '../../../models/app.models'
import { Point } from '../types'

export interface VehicleGeometry {
    poly: Point[],
    strokeColor?: string,
    fillColor?: string,
    strokeWidth?: number,
}

export interface VehiclePart {
    tool_type: string,
    reference_point: string,
    angle: number,
    length: number,
    width: number,
    chassis_position: Position,
    rear_joint_position: Position,
    axles?: Axle[]
}

export interface Vehicle { 
    status: string,
    referencePoint: string;
    position: Position,
    parts: VehiclePart[],
    id: string | number,
    transitionStartTime: any,
    deltaAnimation: number,
    picture: string;
}

export class VehicleInstance implements Vehicle {
    position: Position;
    parts: VehiclePart[]; 
    referencePoint: string;
    id: string | number;
    status: string;
    transitionStartTime: any;
    deltaAnimation: number;
    picture: string;


    constructor(presence: Presence){
        this.id = presence.id;
        this.referencePoint = presence.referencePoint;
        const vehiclePosition = { x: presence.pose.x, y: presence.pose.y, id: presence.id };
        const presenceSpec = presence.specification as any;
        if (!presenceSpec) {return null}
        const parts: Specification[] = presenceSpec.vehicles ? presenceSpec.vehicles : presenceSpec

        try {
                const vehicleParts: VehiclePart[] = parts.map((spec, index) => {
                    let angle = 0;
                    if (presence.pose.orientations &&  presence.pose.orientations[ index ] !== null) {
                        angle = presence.pose.orientations[ index ] / 1000;
                    } else {
                        angle = presence.pose.orientation? presence.pose.orientation : 0;
                    }
                    return {
                            angle: angle,
                            ...spec}
                })

                this.parts= vehicleParts;
            
        } catch (error) {
            console.log("VehicleInstance Render Error")
            console.log(error, parts);
            this.parts = [];
        }



        this.position= { x: vehiclePosition.x, y: vehiclePosition.y };
    }

    public rotate(rot_angle:number){
        this.parts.forEach((part, index) => {
            part.angle += rot_angle/1000;
        })
        return rot_angle;
    }

    public setAngle(angle:number[]){
        this.parts.forEach((part, index) => {
            part.angle = angle[ index ] != null ? angle[ index ] / 1000 : angle[ 0 ] / 1000;
        });
    }

}

function drawJoint (ctx: CanvasRenderingContext2D, mmPerPx: number, clickVehicle: boolean, rearJointLocal?: Position, scale=1) {
    if (!clickVehicle) {
        ctx.strokeStyle = '#f75'
    }
    ctx.lineWidth = mmPerPx
    ctx.beginPath()
    ctx.arc(0, 0, scale*50, 0, Math.PI * 2)
    ctx.closePath()
    ctx.stroke()
    ctx.fill()

    if (rearJointLocal) {
        if (!clickVehicle) {
            ctx.strokeStyle = 'rgba(211,35,12,0.2)'
        }
        ctx.lineWidth = mmPerPx * 2
        ctx.beginPath()
        ctx.arc(scale*rearJointLocal.x, scale*rearJointLocal.y, scale*100, 0, Math.PI * 2)
        ctx.closePath()
        ctx.stroke()
        ctx.fill()
    }
}

export const fillColors = {
    chassis: 'rgba(0, 150, 0, 0.3)',
    default: 'rgba(100,100,100,0.8)',
    hitch: 'rgba(100,100,100,0.4)',
    selected: 'rgba(255,255,255,0.5)',
    window: 'rgba(200,255,255, 0.4)',
    busy: 'rgba(200,100,100, 0.8)',
}
const strokeColors = {
    axles: 'rgba(0,0,255,0.3)',
    chassis: 'rgb(0, 100, 0)',
    default: 'rgba(50,50,50,0.8)',
    hitch: 'rgba(0,0,0, 0.7)',
}

function createTransporterGeometry (part: VehiclePart) {
    const geometry: VehicleGeometry[] = []
    const width = part.width
    const length = part.length
    const chassisFrontPoints = [
        {x: 800, y: -width / 2},
        {x: 400, y: -(width / 2 * 0.95)},
        {x: 200, y: -(width / 2 * 0.85)},
        {x: 50, y: -(width / 4)},
        {x: 0, y: -(width / 12)},
        {x: 0, y:  (width / 12)},
        {x: 50, y:  (width / 4)},
        {x: 200, y: (width / 2 * 0.85)},
        {x: 400, y: (width / 2 * 0.95)},
        {x: 800, y: (width / 2)},
    ]
    const chassisRearPoints = [
        {x: length - 200, y: (width / 2 - 0)},
        {x: length - 100, y: (width / 2 - 50)},
        {x: length - 50, y: (width / 2 - 100)},
        {x: length, y: (width / 6)},
        {x: length, y: -(width / 6)},
        {x: length - 50, y: -(width / 2 - 100)},
        {x: length - 100, y: -(width / 2 - 50)},
        {x: length - 200, y: -(width / 2 - 0)},
    ]
    const chassis = {
        fillColor: fillColors.chassis,
        poly: chassisFrontPoints.concat(chassisRearPoints),
        strokeColor: strokeColors.chassis,
    }

    const roofFrontOffset = 1500
    const roofRearOffset = 200
    const roof = {
        // fillColor: fillColors.chassis,
        poly: chassisFrontPoints.map(xy => ({x: xy.x + roofFrontOffset, y: xy.y * 0.9})).concat(chassisRearPoints.map(xy => ({x: xy.x - roofRearOffset, y: xy.y * 0.9}))),
        // strokeColor: strokeColors.chassis,
    }
    const window = {
        fillColor: fillColors.window,
        poly: chassisFrontPoints.map(xy => ({x: xy.x + roofFrontOffset / 2, y: xy.y * 0.95})).concat(chassisRearPoints.map(xy => ({x: xy.x - roofRearOffset / 2, y: xy.y * 0.95}))),
        strokeColor: strokeColors.chassis,
    }
    const lightInset = 50
    const light1 = {
        fillColor: fillColors.window,
        poly: [
            {x: 400 + lightInset, y: (width / 2 * 0.95 - lightInset)},
            {x: 200 + lightInset, y: (width / 2 * 0.85 - lightInset)},
            {x: 50 + lightInset, y: (width / 4 - lightInset)},
            {x: 200 + lightInset + 100, y: (width / 2 * 0.85 - lightInset)},
            {x: 400 + lightInset + 100, y: (width / 2 * 0.95 - lightInset)},
        ],
        strokeColor: strokeColors.chassis,
    }
    const light2 = {
        fillColor: light1.fillColor,
        poly: light1.poly.map(xy => ({x: xy.x, y: -xy.y})),
        strokeColor: light1.strokeColor,
    }
    geometry.push(chassis)
    geometry.push(window)
    geometry.push(light1)
    geometry.push(light2)
    geometry.push(roof)
    return geometry
}

function createBaseTruckGeometry (part: VehiclePart): VehicleGeometry[] {
    const geometry: VehicleGeometry[] = []
    const width = part.width
    const length = part.length
    const chassis = {
        fillColor: fillColors.chassis,
        poly: [
            {x: 100, y: -width / 2},
            {x: 0, y: -(width / 2 - 100)},
            {x: 0, y:  (width / 2 - 100)},
            {x: 100, y: width / 2},
            {x: 1700, y: width / 2},
            {x: 1700, y: 400},

            {x: length + 50, y: 400},
            {x: length + 50, y: width / 2},
            {x: length + 100, y: width / 2},
            {x: length + 100, y: -width / 2},
            {x: length + 50, y: -width / 2},
            {x: length + 50, y: -400},

            {x: 1700, y: -400},
            {x: 1700, y: -width / 2},
        ],
        strokeColor: strokeColors.chassis,
    }
    const cabinRoof = {
        // fillColor: fillColors.chassis,
        poly: [
            // mirror
            {x: 400, y: -(width / 2 - 50)},
            {x: 400, y: -(width / 2 + 50)},
            {x: 500, y: -(width / 2 + 250)},
            {x: 600, y: -(width / 2 + 250)},
            {x: 500, y: -(width / 2 + 50)},
            {x: 450, y: -(width / 2 - 50)},

            // front
            {x: 400, y: -(width / 2 - 50)},
            {x: 300, y: -(width / 2 - 150)},
            {x: 300, y:  (width / 2 - 150)},
            {x: 400, y: (width / 2 - 50)},

            // mirror
            {x: 450, y: (width / 2 - 50)},
            {x: 500, y: (width / 2 + 50)},
            {x: 600, y: (width / 2 + 250)},
            {x: 500, y: (width / 2 + 250)},
            {x: 400, y: (width / 2 + 50)},
            {x: 400, y: (width / 2 - 50)},

            {x: 1650, y: (width / 2 - 50)},
            {x: 1650, y: -(width / 2 - 50)},
        ],
        strokeColor: strokeColors.chassis,
    }
    const cabinWindow = {
        fillColor: fillColors.window,
        // fillColor: 'rgba(0,0,0,0.4)'
        poly: [
            // window
            {x: 200, y: (width / 2 - 30)},
            {x: 100, y: (width / 2 - 130)},
            {x: 100, y: -(width / 2 - 130)},
            {x:  200, y: -(width / 2 - 30)},

            {x: 400, y: -(width / 2 - 50)},
            {x: 300, y: -(width / 2 - 150)},
            {x: 300, y:  (width / 2 - 150)},
            {x: 400, y: (width / 2 - 50)},
        ],
        strokeColor: strokeColors.chassis,
    }
    if (part.axles) {
        const axleGeometry: VehicleGeometry = {
            poly: [],
            strokeColor: strokeColors.axles,
            strokeWidth: 100,
        }
        part.axles.forEach(axle => {
            const pos = axle.position
            // start and end in the middle, go to left and right to draw the length of the axle
            axleGeometry.poly.push(pos)
            axleGeometry.poly.push({x: pos.x, y: pos.y - axle.wheel_positions[0]})
            axleGeometry.poly.push({x: pos.x, y: pos.y + axle.wheel_positions[0]})
            axleGeometry.poly.push(pos)
        })
        geometry.push(axleGeometry)
    }

    geometry.push (chassis)
    geometry.push (cabinWindow)
    geometry.push (cabinRoof)
    return geometry
}

function createBoxTruckGeometry (part: VehiclePart) {
    const geometry = createBaseTruckGeometry(part)
    const cargo = { // cargo
        poly: [
            {x: 1800, y: -part.width / 2},
            {x: 1800, y: part.width / 2},
            {x: part.length, y: part.width / 2},
            {x: part.length, y: -part.width / 2},
        ],
    }
    geometry.push(cargo)
    return geometry
}

function createTractorGeometry (part: VehiclePart) {
    const geometry = createBaseTruckGeometry(part)
    const hitchPos = part.rear_joint_position
    const hitch = {
        fillColor: fillColors.hitch,
        poly: [
            {x: hitchPos.x + 100, y: hitchPos.y + 40},
            {x: hitchPos.x - 17, y: hitchPos.y + 40},
            {x: hitchPos.x - 40, y: hitchPos.y + 17},
            {x: hitchPos.x - 40, y: hitchPos.y - 17},
            {x: hitchPos.x - 17, y: hitchPos.y - 40},

            {x: hitchPos.x + 100, y: hitchPos.y - 40},
            {x: hitchPos.x + 400, y: hitchPos.y - 160},
            {x: hitchPos.x + 400, y: hitchPos.y - 250},
            {x: hitchPos.x + 100, y: hitchPos.y - 320},

            {x: hitchPos.x - 160, y: hitchPos.y - 320},
            {x: hitchPos.x - 320, y: hitchPos.y - 160},
            {x: hitchPos.x - 320, y: hitchPos.y + 160},
            {x: hitchPos.x - 160, y: hitchPos.y + 320},

            {x: hitchPos.x + 100, y: hitchPos.y + 320},
            {x: hitchPos.x + 400, y: hitchPos.y + 250},
            {x: hitchPos.x + 400, y: hitchPos.y + 160},
            {x: hitchPos.x + 100, y: hitchPos.y + 40},
        ],
        strokeColor: strokeColors.hitch,
    }
    geometry.push(hitch)
    return geometry
}

function createDrawBarGeometry (part: VehiclePart) {
    const geometry: VehicleGeometry[] = []
    if (part.axles) {
        part.axles.forEach(axle => {
            const axleGeometry: VehicleGeometry = {
                poly: [],
                strokeColor: strokeColors.axles,
                strokeWidth: 100,
            }
            const pos = axle.position
                // start and end in the middle, go to left and right to draw the length of the axle
            axleGeometry.poly.push({x: pos.x - part.chassis_position.x, y: pos.y - axle.wheel_positions[0]})
            axleGeometry.poly.push({x: pos.x - part.chassis_position.x, y: pos.y + axle.wheel_positions[0]})

            geometry.push(axleGeometry)
        })
    }
    const chassis = { // chassis
        fillColor: 'transparent',
        poly: [
            {x: 0, y: 0},
            {x: part.length * 0.6, y: -150},
            {x: part.length * 0.6, y: 0},
            {x: part.length * 0.6, y: -150},
            {x: part.length, y: -400},
            {x: part.length, y: 400},
            {x: part.length * 0.6, y: 150},
            {x: part.length * 0.6, y: 0},
            {x: part.length * 0.6, y: 150},
        ],
        strokeColor: strokeColors.chassis,
        strokeWidth: 60,
    }
    geometry.push(chassis)
    return geometry
}

function createTrailerGeometry (part: VehiclePart) {
    const geometry: VehicleGeometry[] = []
    const width = part.width
    const length = part.length
    if (part.axles) {
        part.axles.forEach(axle => {
            const axleGeometry: VehicleGeometry = {
                poly: [],
                strokeColor: strokeColors.axles,
                strokeWidth: 100,
            }
            const pos = axle.position
                // start and end in the middle, go to left and right to draw the length of the axle
            axleGeometry.poly.push({x: pos.x - part.chassis_position.x, y: pos.y - axle.wheel_positions[0]})
            axleGeometry.poly.push({x: pos.x - part.chassis_position.x, y: pos.y + axle.wheel_positions[0]})

            geometry.push(axleGeometry)
        })
    }
    const chassis = { // chassis
        fillColor: fillColors.chassis,
        poly: [
            {x: -100, y: width / 2},
            {x: -50, y: width / 2},
            {x: -50, y: 300},
            {x: length + 50, y: 300},
            {x: length + 50, y: width / 2},
            {x: length + 150, y: width / 2},
            {x: length + 150, y: -width / 2},
            {x: length + 50, y: -width / 2},
            {x: length + 50, y: -300},
            {x: -50, y: -300},
            {x: -50, y: -width / 2},
            {x: -100, y: -width / 2},
        ],
        strokeColor: strokeColors.chassis,
    }
    const cargo = {
        poly: [
            {x: 0, y: -part.width / 2},
            {x: 0, y: part.width / 2},
            {x: part.length, y: part.width / 2},
            {x: part.length, y: -part.width / 2},
        ],
    }
    geometry.push(chassis)
    geometry.push(cargo)

    return geometry
}

function guessWhatIsThat(part: VehiclePart, partNumber: number) {
    if (partNumber === 0) {
        if (part.rear_joint_position.x >= part.length - 300) {
            if (part.length < 7000 && part.width < 2100 && part.axles && part.axles.length < 3) {
                return createTransporterGeometry(part)
            } else {
                return createBoxTruckGeometry(part)
            }
        } else {
            return createTractorGeometry(part)
        }
    } else {
        if (part.length < 3000) {
            return createDrawBarGeometry(part)
        } else {
            return createTrailerGeometry(part)
        }
    }
}



function createGeometry(part: VehiclePart, partNumber: number): VehicleGeometry[] | undefined {
            
    if (!part.tool_type) {
        return guessWhatIsThat(part, partNumber);
    }


    switch(part.tool_type){
        case 'truck':
            return createBoxTruckGeometry(part);
            break;
    
        case 'trailer':
            return createTrailerGeometry(part);
            break;   
            
        case 'truck_frame':
            return createDrawBarGeometry(part);
            break;   

        case 'transporter':
            return createTransporterGeometry(part);
            break;   

        case 'tractor':
            return createTractorGeometry(part);
            break;   

        default:
            guessWhatIsThat(part, partNumber);
            break;
    }

}

function drawGeometry (ctx: CanvasRenderingContext2D, geometry: VehicleGeometry[], clickVehicle: boolean, scale=1) {
    for (let i = 0; i < geometry.length; i++) {
        const geom = geometry[i]

        if (geom.poly.length < 2) {
            continue
        }
        ctx.beginPath()
        ctx.lineJoin = 'round'
        const originalFill = ctx.fillStyle
        const originalStroke = ctx.strokeStyle
        const originalLineWidth = ctx.lineWidth
        if (!clickVehicle) {
            if (geom.fillColor) {
                ctx.fillStyle = geom.fillColor
            }
            if (geom.strokeColor) {
                ctx.strokeStyle = geom.strokeColor
            }
        }
        if (geom.strokeWidth) {
            ctx.lineWidth = geom.strokeWidth*scale;
        }
        ctx.moveTo(scale*geom.poly[0].x, scale*geom.poly[0].y)
        for (let p = 1; p < geom.poly.length; p++) {
            ctx.lineTo(scale*geom.poly[p].x, scale*geom.poly[p].y)
        }
        ctx.closePath()
        ctx.stroke()
        ctx.fill()
        ctx.fillStyle = originalFill
        ctx.strokeStyle = originalStroke
        ctx.lineWidth = originalLineWidth
    }
}

function drawPart (ctx: CanvasRenderingContext2D, angle: number, width: number, length: number, pivotWorld: Position, mmPerPx: number, clickVehicle: boolean, geometry?: VehicleGeometry[], rearJointLocal?: Position, scale=1) {
    ctx.save();

    drawJoint(ctx, mmPerPx, clickVehicle, rearJointLocal, scale);

    ctx.lineWidth = mmPerPx * 2;
    ctx.translate(scale*pivotWorld.x, scale*pivotWorld.y);

    if (!clickVehicle) {
        const preLineWidth = ctx.lineWidth;
        ctx.lineWidth *= 2;
        ctx.strokeStyle = strokeColors.default;
        ctx.stroke();
        ctx.lineWidth = preLineWidth;
        ctx.strokeStyle = strokeColors.default;
    }

    if (geometry) {
        drawGeometry(ctx, geometry, clickVehicle, scale)
    } else {
        ctx.rect(0, -scale*width / 2, scale*length, scale*width);
        ctx.stroke();
        ctx.fill();
    }

    ctx.restore()

    return
}

function drawWheel (ctx: CanvasRenderingContext2D, angle: number, width: number, height: number, pivotWorld: Position, mmPerPx: number, clickVehicle: boolean, scale=1) {
    const wheelCornerRadius = 30*scale;
    if (!clickVehicle) {
        ctx.strokeStyle = '#aaa';
        ctx.fillStyle = '#333';
    }
    ctx.lineWidth = Math.min(30*scale, mmPerPx * 2);

    ctx.rotate(angle);
    ctx.save();
    ctx.translate(-pivotWorld.x, -pivotWorld.y);
    ctx.beginPath();
    ctx.moveTo(scale*wheelCornerRadius, 0);
    ctx.arcTo(scale*height, 0, scale*height, scale*width, wheelCornerRadius);
    ctx.arcTo(scale*height, scale*width, 0, scale*width, wheelCornerRadius);
    ctx.arcTo(0, scale*width, 0, 0, wheelCornerRadius);
    ctx.arcTo(0, 0, scale*height, 0, wheelCornerRadius);
    ctx.stroke();
    ctx.fill();
    ctx.restore();
}

export function renderVehicle (ctx: CanvasRenderingContext2D, vehicle: Vehicle, mmPerPx: number, selected: boolean, clickableColor?: string,scale=1) {
    try {
        return _renderVehicle(ctx, vehicle,mmPerPx,selected,clickableColor,scale);
    } catch (error) {
        
    }
}


function _renderVehicle (ctx: CanvasRenderingContext2D, vehicle: Vehicle, mmPerPx: number, selected: boolean, clickableColor?: string,scale=1) {
    ctx.lineWidth = 2 * mmPerPx
    if (clickableColor) {
        // ctx.strokeStyle = clickableColor
        ctx.fillStyle = clickableColor;
    } else {
        ctx.strokeStyle = 'blue';
    }
    if (vehicle.parts) {
        ctx.save()
        if (!clickableColor) {
            if (selected) {
                ctx.fillStyle = fillColors.selected;
            } else {
                ctx.fillStyle = fillColors.default;
            }
        }

        // The drawing starts from the chassis. These delta values represents the origin of the drawing canvas.
        // If the value of the chassis is positive (negative), it is drawn on the right(left) from the origin.
        let delta_x = 0;
        let delta_y = 0;
        try {
            let referencePoint = vehicle.parts[0].reference_point? vehicle.parts[0].reference_point:vehicle.referencePoint;
            if (!referencePoint) referencePoint = 'front_axle_center';

            let axleX, axleY;
            const mumAxles = vehicle.parts[0].axles.length;
            if (['first_axis', 'first_axle','front_axle_center', 'first_axle_center', 'front_axle'].includes(referencePoint)) {
                referencePoint = 'front_axle_center';
            }


            if (['rear_axis', 'rear_axle', 'last_axle_center', 'rear_axle_center'].includes(referencePoint)) {
                referencePoint =  'rear_axle_center';
            }

            switch (referencePoint) {


                case 'front_axle_center':
                    // Here, the front chassis is the local (0, 0), we need to shift by the front-axle to define the the global coordinates.
                    axleX = vehicle.parts[0].axles[0].position.x;
                    axleY = vehicle.parts[0].axles[0].position.y;
                    delta_x = axleX*Math.cos(vehicle.parts[0].angle) + axleY*Math.sin(vehicle.parts[0].angle);
                    delta_y = axleX*Math.sin(vehicle.parts[0].angle) + axleY*Math.cos(vehicle.parts[0].angle);
                    break;



                case 'rear_axle_center':
                    // Here, the front chassis is the local (0, 0), we need to shift by the rear-axle to define the the global coordinates.
                    const mumAxles = vehicle.parts[0].axles.length;
                    axleX = vehicle.parts[0].axles[mumAxles-1].position.x;
                    axleY = vehicle.parts[0].axles[mumAxles-1].position.y;
                    delta_x = axleX*Math.cos(vehicle.parts[0].angle) + axleY*Math.sin(vehicle.parts[0].angle);
                    delta_y = axleX*Math.sin(vehicle.parts[0].angle) + axleY*Math.cos(vehicle.parts[0].angle);
                    break;    


                case 'connection_point':
                    // This is a little confusing: there is no field informing the connection point distances to shift.
                    // The connection_point is always the local (0, 0) coordinate. 
                    delta_x = 0
                    delta_y = 0
                    break;
            
                default:
                    // Here, we handle multiple axles vehicles. The reference will be axle-N-center.
                    const numAxles = vehicle.parts[0].axles.length;
                    const axleMatch = referencePoint.match(/axle-(\d+)-center/) || referencePoint.match(/axle_(\d+)_center/);

                    if (axleMatch) {
                        const axleIndex = parseInt(axleMatch[1], 10); 

                        if (axleIndex >= 0 && axleIndex < numAxles) {
                            const axleCenterX = vehicle.parts[0].axles[axleIndex].position.x;
                            const axleCenterY = vehicle.parts[0].axles[axleIndex].position.y;
                            const angle = vehicle.parts[0].angle;

                            delta_x = axleCenterX * Math.cos(angle) + axleCenterY * Math.sin(angle);
                            delta_y = axleCenterX * Math.sin(angle) + axleCenterY * Math.cos(angle);
                        } else {
                            console.error("Invalid axle index");
                        }
                    } else {
                        console.error("Invalid reference point format");
                    }

                    break;
            }

        } catch (error) {
            // console.error("vehicle parts data structure", error);
        }

        ctx.translate(-vehicle.position.x + delta_x, vehicle.position.y - delta_y);
        ctx.rotate(-Math.PI);
        vehicle.parts.forEach((part, i) => {
            ctx.lineWidth = mmPerPx * 2;
            ctx.rotate(-part.angle);
            ctx.save();
            // ctx.translate(-part.chassis_position.x, -part.chassis_position.y)
            if (part.axles) {
                part.axles.forEach(axle => {
                    // ctx.strokeRect(0,0,axle.position.x ,axle.position.y)
                    axle.wheel_positions.forEach(wheel_pos => {
                        try {
                            drawWheel(ctx,
                                0,
                                axle.tire_width,
                                axle.tire_diameter,
                                { x: -scale*axle.position.x + scale*axle.tire_diameter / 2, y: -scale*axle.position.y + scale*wheel_pos + scale*axle.tire_width / 2 },
                                mmPerPx,
                                !!clickableColor,
                                scale
                            )
                            
                        } catch (error) {
                            // console.log("Drawing error: ", axle );
                        }
                        
                    })
                })
            }
            ctx.restore();

            const geometry: VehicleGeometry[] | undefined = createGeometry(part, i);
            drawPart(ctx,
                part.angle,
                part.width,
                part.length,
                part.chassis_position,
                mmPerPx,
                !!clickableColor,
                geometry,
                part.rear_joint_position,
                scale)

            ctx.translate(scale*part.rear_joint_position.x, scale*part.rear_joint_position.y);
            // ctx.translate(part.rear_joint_position.x - part.chassis_position.x, part.rear_joint_position.y - part.chassis_position.y)
        })
        ctx.restore();
    }
}
